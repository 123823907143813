import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import TeamMemberPanel from "../components/TeamMemberPanel";
import BannerWithEyebrow from "../components/banners/BannerWithEyebrow";
import ContactBanner from "../components/banners/ContactBanner";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";
import { breakpoints } from "../styles/breakpoints";

export const query = graphql`
  query TeamMembers {
    allContentfulTeamMember {
      nodes {
        name
        title
        description {
          description
        }
        image {
          url
        }
        instagram
        github
      }
    }
  }
`;

export default function OurTeam({ data }: any) {
  const {
    allContentfulTeamMember: { nodes: members },
  } = data;

  return (
    <Layout>
      <Seo title="Our Team" />
      <BannerWithEyebrow eyebrow="Reach us" headline="Meet the team" />
      <MemberGrid>
        {members.map(member => (
          <TeamMemberPanel
            key={member.name}
            name={member.name}
            title={member.title}
            description={member.description.description}
            imageUrl={member.image.url}
            instagramUrl={member.instagram}
            githubUrl={member.github}
          />
        ))}
      </MemberGrid>
      <ContactBanner />
    </Layout>
  );
}

const MemberGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 24px;

  ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }
`;
