import * as React from "react";
import { FaGithub, FaInstagram } from "react-icons/fa";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { H4, H5, H6 } from "../styles/text-styles";

interface TeamMemberProps {
  name: string;
  title: string;
  description: string;
  imageUrl: string;
  githubUrl: string;
  instagramUrl: string;
}

export default function TeamMemberPanel(props: TeamMemberProps) {
  const { imageUrl, name, title, description, githubUrl, instagramUrl } = props;

  return (
    <Panel>
      <Image src={imageUrl} alt={name} />
      <Name>{name}</Name>
      {title && <Title>{title}</Title>}
      <Description>{description}</Description>
      <LinkBlock>
        {githubUrl && (
          <a href={instagramUrl} target="_blank">
            <FaInstagram color="white" size={24} />
          </a>
        )}
        {instagramUrl && (
          <a href={githubUrl} target="_blank">
            <FaGithub color="white" size={24} />
          </a>
        )}
      </LinkBlock>
    </Panel>
  );
}

const Panel = styled.div`
  padding: 24px;
  border-radius: 12px;
  background-color: ${colors.gray2};
  /* min-width: 400px; */

  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;

  ${breakpoints.md} {
    padding: 36px;
  }
`;

const Image = styled.img`
  max-height: 140px;
  max-width: 140px;
  border-radius: 70px;
`;

const Name = styled(H4)`
  margin-top: 12px;

  ${breakpoints.md} {
    margin-top: 16px;
  }
`;

const Title = styled(H5)`
  color: ${colors.gray4};
`;

const Description = styled(H6)`
  color: ${colors.gray4};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const LinkBlock = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;
