import React from "react";
import styled from "styled-components";

import { colors } from "../../styles/colors";
import { ContentWrapper, Section } from "../../styles/structures";
import { H1, H4, H5 } from "../../styles/text-styles";

export default function CtaBanner() {
  return (
    <Section>
      <ContentWrapper>
        <Eyebrow>Help us out</Eyebrow>
        <Headline>Let us know what you ride</Headline>
        <BlockText>
          Currently, WhatTheyRide relies on self research as well as companies
          providing us athlete data to keep our pages accurate and up to date.
          If you are a sponsor or professional athlete who would like to have
          data added, corrected, or updated, we'd love to hear from you. Send us
          a message <Mail href="mailto:info@whattheyride.com">here</Mail>. We
          may require verification to ensure our data is accurate.
        </BlockText>
      </ContentWrapper>
    </Section>
  );
}

const Eyebrow = styled(H5)`
  color: ${colors.primary2};
  margin-bottom: 24px;
`;

const Headline = styled(H1)`
  margin-bottom: 36px;
`;

const BlockText = styled(H4)`
  color: ${colors.gray4};
`;

const Mail = styled.a`
  color: ${colors.white};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
